<template>
  <!-- 任务管理详情 -->
  <div class="mission-details grey-bg">
    <div class="mission-con">
      <div class="examine">
        <div class="title">完成任务奖励{{ receptions.task && receptions.task.rule.detail.amount }}经验值</div>
        <div class="title-tip">完成须知：{{ receptions.task && receptions.task.notice }}</div>
      </div>
        <div class="team-info vertical-center" v-if="is_show">
            <img class="team-info-img" :src="receptions.task.cover_picture ? receptions.task.cover_picture : $confStatic.taskImg" alt="">
            <div class="team-info-con">
                <div class="team-title white-space">{{ receptions.task.name }}</div>
                <div class="team-complete">已有<span class="team-complete-span">{{ $handle.tranNumber(receptions.task.count, 3) }}</span>人完成</div>
            </div>
        </div>
      <div class="user-case">
        <div class="user-case-con">
          <div class="task-step-one">
            <div class="step-title">1.用户已上传截图</div>
            <div class="step-tip"></div>
            <div class="step-one-img" >
              <img class="one-img" :src="item" alt="" v-for="(item,index) in receptions.content" :key="index" @click="clickImg(item)">
            </div>
          </div>
          <div class="task-step-one">
            <div class="step-title">2.操作步骤</div>
            <div class="step-tip"></div>
            <div class="uploader"></div>
            <div class="step-one-img rich-text" v-html="receptions.task && receptions.task.step">
            </div>
          </div>
        </div>
        <div class="audit-status">
<!--          <div class="status" v-if="receptions.status == 0">待审核</div>-->
          <div class="status failed" v-if="receptions.status == -1">审核未通过</div>
          <div class="status" v-else-if="receptions.status == 1">审核已通过</div>
        </div>
      </div>

    </div>

    <div class="safety-height">
      <div class="audit-status-btn"  v-if="receptions.status == 0">
        <div class="audit-status-con">
          <div class="reject" @click="examine('rejection')">驳回</div>
          <div class="adopt" @click="examine('pass')">审核通过</div>
        </div>
      </div>
    </div>
    <div>
      <!--审核驳回提示开始-->
      <van-overlay :show="isRejectShow" z-index="9999">
        <div class="overlay-reject-con align-center">
          <div class="overlay-reject">
            <div class="overlay-reject-head">
              <div></div>
              <div class="overlay-reject-text">确认审核</div>
              <img class="overlay-reject-head-img" src="~assets/images/user/overlay-cancel.png" @click="closeOverla('rejection')"/>
            </div>
            <div class="overlay-reject-weiper">
              <div class="overlay-reject-text">选择以下驳回原因驳回后将无法恢复，确定继续吗？</div>
              <div class="overlay-reject-info">
                <van-radio-group v-model="checkedRadio"  class="overlay-reject-radio-group">
                  <!--驳回提示选项-->
                  <van-radio use-icon-slot value="{{ radio }}" :name="item.id" label-class="label-class" class="overlay-reject-radio-group-item" v-for="item in rejectTip" :key="item.id">
                    <template #icon="props">
                      <img class="overlay-reject-icon" :src="checkedRadio === item.id ? require('assets/images/user/overlay-redio2.png') : require('assets/images/user/overlay-redio1.png')"/>
                    </template>
                    <template #default="defaul">
                      <div class="label-class">{{ item.text }}</div>
                    </template>
                  </van-radio>
                  <van-field
                      v-model="otherMessage"
                      type="textarea"
                      placeholder="请输入其他原因"
                      class="other"
                      v-show="checkedRadio === 3"
                  />
                  <!--驳回提示选项-->
                </van-radio-group>
              </div>
            </div>
            <div class="overlay-btn">
              <div class="confirm" @click="closeOverla('rejection')">再想想</div>
              <div class="cancel" @click="operation('rejection', ids)">确定</div>
            </div>
          </div>
        </div>
      </van-overlay>
      <!--审核驳回提示结束-->

      <!--确认审核提示开始-->
      <van-overlay :show="isConfirmShow" z-index="9999">
        <div class="overlay-confirmation-audit-con align-center">
          <div class="overlay-confirmation-audit">
            <div class="overlay-confirmation-head">
              <div class="overlay-reject-text">确认审核</div>
              <img class="overlay-confirmation-head-img" src="~assets/images/user/overlay-cancel.png" @click="closeOverla('pass')"/>
            </div>
            <div class="overlay-confirmation-body vertical-center">
              <img class="overlay-confirmation-tip-img" src="~assets/images/user/overlay-tip.png"/>
              <div class="overlay-confirmation-tip-text">一旦通过，无法撤回。是否确认审核通过？</div>
              <div class="overlay-btn">
                <div class="cancel" @click="closeOverla('pass')">取消</div>
                <div class="confirm" @click="operation('pass')">确定</div>
              </div>
            </div>
          </div>
        </div>
      </van-overlay>
      <!--确认审核提示结束-->
      <!--点击图片放大开始-->
<!--      <van-overlay :show="isShowImage" @click="isShowImage = false">
        <div class="overlay-show-img align-center">
          <van-image width="300" :src="image" />
        </div>
      </van-overlay>-->
      <!--点击图片放大结束-->
    </div>
  </div>


</template>

<script>
import { ImagePreview } from 'vant';

export default {
  name: "sign",
  data(){
    return {
      receptions:{},
      signData: [],       //签到信息
      showOverlay: false,
      isConfirmShow: false, //确认审核提示
      isRejectShow: false, //审核驳回提示
      otherMessage:'',
      checkedRadio: 0,
      rejectTip: [
        {id: 0, text: '截图不清晰，无法辨别，请重新上传'},
        {id: 1, text: '涉嫌违反法律法规、社会公德良知，涉及反动、泄密、色情赌博、诈骗、售假、暴力等内容。'},
        {id: 2, text: '未按正确流程操作'},
        {id: 3, text: '其他原因'},
      ],
      image:"",
      isShowImage: false,
        is_show:false
    }
  },
  created() {
    this.getReceptions()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'taskManagement') {
        to.query.task_id=  this.$route.query.task_id
        to.query.task_name=  this.$route.query.task_name
        to.query.task_index=  this.$route.query.task_index
        to.query.team_id=  this.$route.query.team_id
        to.query.team_name=  this.$route.query.team_name
        to.query.team_index=  this.$route.query.team_index
    }
    next();
  },
  methods:{
    //获取任务信息
    getReceptions(){
      let _this = this
      let url = _this.$api.Tasknot + '/' + _this.$route.params.id
      _this.$http.get(url,true).then(res => {
        if (res.data.success){
          _this.receptions = res.data.data
            _this.is_show = true
        }else {
            setTimeout(() => {
                _this.$toast(res.data.message)
                _this.$router.replace("/user/task")
            },1500)
        }
      })
    },
    // 点击底部按钮
    examine(type){
        this.checkedRadio = 0

        if (type === 'rejection'){
        this.isRejectShow = true
      }else if (type === 'pass'){
        this.isConfirmShow = true
      }
    },
    //点击取消按钮
    closeOverla(type){
      if (type === 'pass'){
        this.isConfirmShow = false
      }else if (type === 'rejection'){
        this.otherMessage = ''
        this.isRejectShow = false
      }
    },
    //点击通过按钮
    operation(type){
      let _this = this
      _this.page = 1
      _this.taskInfo = []

        let url = _this.$api.Notifications
      if (type === 'pass'){
        let data = {
          action: 'access',
          source: 'task',
          ids: _this.receptions.id
        }
        _this.$http.post(url,data,true).then( res => {
          if(res.data.success){
            _this.isConfirmShow = false
            _this.getReceptions()
          }else {
              _this.isConfirmShow = false

              setTimeout(() => {
                  _this.$toast(res.data.message)
                  _this.$router.replace("/user/task")
              }, 1500)
          }
        } )

      }else if (type === 'rejection'){
        let data = {
          action: 'reject',
          source: 'task',
          // remark: '',
          remark: _this.rejectTip[this.checkedRadio].text,
          ids: _this.receptions.id
        }
        if (_this.checkedRadio === 3){
            if (_this.otherMessage.trim() === ''){
                _this.$toast("请输入驳回原因")
            }
          data.remark = _this.otherMessage
        }
        _this.$http.post(url,data,true).then( res => {
          if(res.data.success){
            _this.otherMessage = ''
            _this.isRejectShow = false
            _this.getReceptions()
          }else {
              _this.otherMessage = ''
              _this.isRejectShow = false
              setTimeout(() => {
                  _this.$toast(res.data.message)
                  _this.$router.replace("/user/task")
              }, 1500)

          }
        } )
      }
    },
    // 点击图片放大
    clickImg(img){

        ImagePreview({
            images: [img],
        })
    }

  },
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/task-management/show.scss";
$r: 750/16/1rem;
.other{
    width: 521/$r;
    height: 130/$r;
    margin-top: 24/$r;
    background: #F7F7F7;
    font-size: 24/$r;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    line-height: 36/$r;
}
:deep(.van-cell){
    padding: 20/$r 46/$r 14/$r 20/$r;
}
</style>